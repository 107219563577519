import React, {
  // useEffect,
  // useState,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { PlatformContext } from './Context';
// import config from './gwconfig';

const PlatformProvider = ({ children }) => {
  // const [platformId, setPlatformId] = useState(null);
  // const [clientId, setClientId] = useState('ee07aaaf-9637-46fa-910d-c918e6efc883');
  // const [initiateLoginEndpoint, setInitiateLoginEndpoint] = useState('https://teams.governwith.app');
  // const [initialized, setInitialized] = useState(true);
  // const [initError, setInitError] = useState(null);

  const platformId = null;
  const clientId = 'ee07aaaf-9637-46fa-910d-c918e6efc883';
  const initiateLoginEndpoint = 'https://teams.governwith.app';
  const initialized = true;

  // useEffect(() => {
  // const params = new URLSearchParams(window.location.search);
  //   const tpid = params.get('tpid');
  //   if (tpid) {
  //     setPlatformId(tpid);
  //     const platformConfig = config.platforms[tpid];
  //     if (platformConfig) {
  //       setClientId(platformConfig.clientId);
  //       setInitiateLoginEndpoint(currentLoginEndpoint);
  //       setInitialized(true);
  //     } else {
  //       setInitError(`No configuration found for platform ID: ${tpid}`);
  //     }
  //   } else {
  //     setInitError('No platform ID provided in URL');
  //   }
  // }, []);

  const wrapped = useMemo(() => ({
    platformId,
    clientId,
    initiateLoginEndpoint,
    initialized
  }), [
    platformId,
    clientId,
    initiateLoginEndpoint,
    initialized
  ]);

  // if (initError) {
  //   return (
  //     <div>
  //       Error:
  //       {initError}
  //     </div>
  //   );
  // }
  //
  // if (!initialized) {
  //   return <div>Loading platform configuration...</div>;
  // }

  return (
    <PlatformContext.Provider value={wrapped}>
      {children}
    </PlatformContext.Provider>
  );
};

PlatformProvider.propTypes = {
  children: PropTypes.node
};

PlatformProvider.defaultProps = {
  children: undefined
};

export default PlatformProvider;
