import React from 'react';
import PropTypes from 'prop-types';
import { useRealmApp } from './RealmAppProvider';
import NoCustomData from './NoCustomData';

const RequireCustomData = ({ children }) => {
  // Only render children user has custom data.
  const app = useRealmApp();

  console.log('app.currentUser:', app.currentUser);

  // // app.currentUser.customData = { 'details': { 'something': 'else' } };
  // const refreshedUser = await app.currentUser.refreshCustomData();

  // console.log('app.currentUser.customData 2:', app.currentUser.customData);
  // console.log('refreshedUser', refreshedUser);

  return Object.prototype.hasOwnProperty.call(app.currentUser.customData, 'details') ? children : <NoCustomData />;
  // return children
};

export default RequireCustomData;

RequireCustomData.propTypes = {
  children: PropTypes.node
};

RequireCustomData.defaultProps = {
  children: undefined
};
