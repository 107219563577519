import { createContext } from 'react';

export const PlatformContext = createContext({
  platformId: undefined,
  clientId: undefined,
  initiateLoginEndpoint: undefined,
  initialised: false
});

export const MSTeamsFxContext = createContext({
  theme: undefined,
  themeString: '',
  teamsUserCredential: undefined,
  userInfo: undefined,
  SSOToken: undefined,
  initialized: false
});

export const RealmAppContext = createContext();
