import React from 'react';
// import { MSTeamsFxContext } from './Context';

const AppError = () => (
  <div>
    Application Loading...
  </div>
);

export default AppError;
