/*
* Teams Test application
 */
// const APP_ID = 'gw-msteams-test-fycvf';

/*
* Teams Production application
 */
const APP_ID = 'governwith-teams-iwtbydz';

export default APP_ID;
