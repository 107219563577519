import React, { useContext } from 'react';
import { MSTeamsFxContext } from 'App/Context';

const NoCustomData = () => {
  // try to login with our SSOToken from Teams (if we have it yet)
  const { SSOToken } = useContext(MSTeamsFxContext);
  const { userInfo } = useContext(MSTeamsFxContext);

  console.log('SSOToken', SSOToken);
  console.log('userInfo', userInfo);

  return (
    <div>
      No Custom Data
    </div>
  );
};

export default NoCustomData;
