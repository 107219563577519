import React from 'react';
import PropTypes from 'prop-types';
import AppError from './AppError';
import { useRealmApp } from './RealmAppProvider';

const RequireLoggedInUser = ({ children }) => {
  // Only render children if there is a logged in user.
  const app = useRealmApp();

  return app.currentUser ? children : <AppError />;
  // return children;
};

export default RequireLoggedInUser;

RequireLoggedInUser.propTypes = {
  children: PropTypes.node
};

RequireLoggedInUser.defaultProps = {
  children: undefined
};
