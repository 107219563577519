import React, { lazy, Suspense } from 'react';
import RequireCustomData from 'App/RequireCustomData';
import PlatformProvider from 'App/PlatformProvider';
import MSTeamsAppProvider from 'App/MSTeamsAppProvider';
import { RealmAppProvider } from 'App/RealmAppProvider';
import RequireLoggedInUser from 'App/RequireLoggedInUser';
import RealmApolloProvider from 'App/RealmApolloProvider';
// import Routing from 'Routing/Routing';
import Loader from 'Loader/Loader';

const Routing = lazy(() => import('Routing/Routing'));

const App = () => (
  <PlatformProvider>
    <MSTeamsAppProvider>
      <RealmAppProvider>
        <RequireLoggedInUser>
          <RequireCustomData>
            <RealmApolloProvider>
              <Suspense fallback={<Loader />}>
                <Routing />
              </Suspense>
            </RealmApolloProvider>
          </RequireCustomData>
        </RequireLoggedInUser>
      </RealmAppProvider>
    </MSTeamsAppProvider>
  </PlatformProvider>
);

export default App;
