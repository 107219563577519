import React, {
  useContext, useState, useEffect, useMemo, useCallback
} from 'react';
import PropTypes from 'prop-types';
import * as Realm from 'realm-web';
import APP_ID from 'App/appConstants';
import {
  // PlatformContext,
  MSTeamsFxContext,
  RealmAppContext
} from './Context';

export const useRealmApp = () => {
  const app = useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      'You must call useRealmApp() inside of a <RealmAppProvider />'
    );
  }
  return app;
};

export const RealmAppProvider = ({ children }) => {
  // const { platformId } = useContext(PlatformContext);
  const [app] = useState(new Realm.App(APP_ID));
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  console.log(errorMsg);
  console.log(loading);

  // Wrap the Realm.App object's user state with React state
  const [currentUser, setCurrentUser] = useState(app.currentUser);

  const logIn = useCallback(async (credentials) => {
    const user = await app.logIn(credentials);
    // If successful, app.currentUser is the user that just logged in
    setCurrentUser(app.currentUser);
    console.log('logIn attempt returned user: ', user);
    return user;
  }, [app]);

  const logOut = useCallback(async () => {
    // Log out the currently active user
    await app.currentUser?.logOut();
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
    setCurrentUser(app.currentUser);
  }, [app]);

  const removeUser = useCallback(async (user) => {
    await app.removeUser(user);
  }, [app]);

  // try to login with our SSOToken from Teams (if we have it yet)
  const { SSOToken } = useContext(MSTeamsFxContext);
  const { userInfo } = useContext(MSTeamsFxContext);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (SSOToken && SSOToken.token) {
          console.log('login attempted');
          await logIn(Realm.Credentials.function({
            SSOToken: SSOToken.token, userInfo
          }));
          setLoading(false);
        }
      } catch (err) {
        setErrorMsg(err.error);
        setLoading(false);
      }
    })();
  }, [SSOToken, logIn, userInfo]);

  const wrapped = useMemo(() => ({
    ...app,
    currentUser,
    logIn,
    logOut,
    removeUser
  }), [
    app,
    currentUser,
    logIn,
    logOut,
    removeUser
  ]);

  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};

RealmAppProvider.propTypes = {
  children: PropTypes.node
};

RealmAppProvider.defaultProps = {
  children: undefined
};
